.thermometer .thermometer__bg-color {
  background: linear-gradient(#2196f3, #8bc34a, #36f4eb);
}

.thermometer .thermometer__draw-b:after {
  background-color: #36f4eb;
}
.thermometer .thermometer__draw-b:before {
  background-color: #36f4eb;
}
canvas {
  height: 100%;
}

.text-center{
  text-align: center;

}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.radio-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.radio-button-input {
    display: none; 
}

.radio-button-label {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.radio-button-circle {
    width: 20px;
    height: 20px;
    border: 5px solid #000;
    border-radius: 50%; 
    background-color: #ffffff;
    display: inline-block;
    position: relative;
    transition:  background-color 0.2s, border-color 0.2s
}

.radio-button-dot1 {
    width: 10px;
    height: 10px;
    background-color: #cacaca;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.radio-button-circle.checked {
    background-color: #56C148; 
    border-color: #56C148;    
    animation: pulse-animation 1s infinite; 
}

.radio-button-circle.disabled {
    background-color: #ddd;
    border-color: #aaa;
}

.radio-button-dot {
    width: 10px;
    height: 10px;
    background-color: #fff; 
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
}



.radio-button-label:focus .radio-button-circle {
    outline: 2px solid #56C148;
}

@keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }
    100% {
      box-shadow: 0 0 0 10px rgba(86, 193, 72, 0.2);  
    }
  }
